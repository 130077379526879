import React,{useState} from "react";
import './App.css';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const TopDestinations = () =>{
    const todayDate = new Date().toISOString().slice(0, 10);

    const date = new Date(Date.now() + ( 3600 * 1000 * 24));
    const tomorrow = date.toISOString().slice(0, 10);
    const [guest, setGuest] = useState(1);
    const [checkIn, setCheckIn] = useState(todayDate);
    const [checkOut, setCheckOut] = useState(tomorrow);
    const [place, setPlace] = useState("");

    
  const [accommodations, setAccommodation] = useState([]);


  const [nights, setNights] = useState();

  const [value, setValue] =  useState([0,90000]);

    const rangeSelector = (event, newValue) => {
  
        async function getData() {
      
          const res = await axios.get(`https://dev.techneeq.org/api/Accomodations?search=h&invFrom=${checkIn}&invTo=${checkOut}&adults=2&min=${value[0]}&max=${value[1]}`);
        
          setAccommodation(res.data.accommodation);
          
        };
        getData();
        setValue(newValue);
      };
      

    return (
        <div>
        <div className="container">
        <div className="row">
        <div className="col-sm-12 text-center mb-3">
        <h2 className="section-heading" id="locations">Unleash the traveler inside you</h2>
        <p>Discover out top destinations</p>
        </div>
        </div>

        <div className="row">
        <div className="col-sm-4 mb-5" id="hunza">
        <Link 
        to=""
        className="link">
        <img src="/images/hunza.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Hunza Valley, GB</span>
        </Link>
      
        </div>

        <div className="col-sm-4 mb-5" id="galiyat">
        <Link 
        to=""
         className="link">
        <img src="/images/galliyat.jpg" alt="image here" className="destination-img"  />
        <span className="bottom-left">Galiyat, KP</span>
        </Link>
        </div>

        <div className="col-sm-4 mb-5" id="naran">
        <Link
         to=""
        className="link">
        <img src="/images/naran.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Naran Valley, KP</span>
        </Link>
        </div>
        </div>


        <div className="row">
        <div className="col-sm-6 mb-5">
        <Link
         to=""
         className="link">
        <img src="/images/shogran2.jpeg" alt="image here" className="destination-img1" />
        <span className="bottom-left">Shogran, KP</span>
        </Link>
        </div>

        <div className="col-sm-6 mb-5">
        <Link to="" 
        className="link">
        <img src="/images/murree1.jpeg" alt="image here" className="destination-img" />
        <span className="bottom-left">Murree , Punjab </span>
        </Link>
        </div>

       
        </div>


        <div className="row">
        <div className="col-sm-4 mb-5" id="kalam">
        <Link to=""
         className="link">
        <img src="/images/kalam.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Kalam Valley, KP</span>
        </Link>
        </div>

        <div className="col-sm-4 mb-5">
      
        <img src="/images/naran.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Naltar Valley , GB</span>
      
        </div>

        <div className="col-sm-4 mb-5" id="lahore">
        <Link to=""
         className="link">
        <img src="/images/lahore.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Lahore, Punjab</span>
        </Link>
      
        </div>
        </div>

        <div className="row">
        <div className="col-sm-6 mb-5">
        <Link to=""
        
        className="link">
        <img src="/images/skardu2.jpg" alt="image here" className="destination-img" />
        <span className="bottom-left">Skardu , GB</span>
        </Link>
        </div>

        <div className="col-sm-6 mb-5">
       
       {/*   <Link to={`listing/Orange%20loft%20Khanpur%20Lake/${checkIn}/${checkOut}/${guest}`} className="link"></Link>*/}
        <img src="/images/khanpur1.jpeg" alt="image here" className="destination-img" />
        <span className="bottom-left">Khanpur </span>
        
      
        </div>

       
        </div>
        </div>
        </div>
    );
}

export default TopDestinations;