import React, { Fragment } from "react";
import { Link , useNavigate } from "react-router-dom";
import './App.css';
import { DropdownButton , Dropdown } from 'react-bootstrap';
import DehazeIcon from '@mui/icons-material/Dehaze';



function Navbar(){

    const btnStyle = {
        float:"right",
        backgroundColor:"white",
        border:"2px solid orange",
        borderRadius:"5px",
        marginTop:"-8px",
        color:"orange",
        textDecoration:"none"
    }
    
  
    const navigate = useNavigate();
    let userEmail = "";
    const logout = () =>{
        localStorage.clear();
        navigate('/');
        
    }
  if(JSON.parse(localStorage.getItem('appState')))  {
        const user = JSON.parse(localStorage.getItem('appState'));
        // console.log(user.name);
         userEmail = user.name;

    }

    const src ="/images/bedbeds-e1723285601133.avif";
    return(
        <div>

        <div className="container p-0">
        <nav class="navbar navbar-expand-lg">
          <Link to="/" className=" logo-img"><img src={src} alt="Logo Image" className="logo-img"/></Link>
          <button className="navbar-toggler "  type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
          
          <span className="navbar-toggler-icon "><DehazeIcon /> </span>
          </button>
          <div class="collapse navbar-collapse hi" id="navbarTogglerDemo03">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item active">
              {/* <Link to="/vr-tour" className="nav-link1 mobilenav">VR Tour</Link> */}
              </li>
              <li class="nav-item nav-item1">
              {/* <Link to="/our-locations" className="nav-link1 mobilenav">Our Locations</Link> */}
              </li>
              <li class="nav-item active">
              {/* <Link to="/rv" className="nav-link1 mobilenav">Motorhome</Link> */}
              </li>
            </ul>
            
            {
              userEmail !== "" ? 
                 <DropdownButton variant="light" className="book-now-btn-hide" title={userEmail} style={btnStyle}  >
                  <Dropdown.Item className="textDecoration"><Link to="/my-profile" className="text-dark">My Profile</Link></Dropdown.Item>
                 <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
               </DropdownButton> : <Link to="" className="boder btn-book book-now-btn-hide">Sign In</Link>
           
              }
            
          </div>
        </nav>
        </div>
        </div>
    );
}

export default Navbar;