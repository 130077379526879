import {React, useState ,useEffect} from "react";
import Navbar from "./Navbar";
import './App.css';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function Slider() {

   

    const todayDate = new Date().toISOString().slice(0, 10); 

    const date = new Date(Date.now() + ( 3600 * 1000 * 24) );
    const tomorrow = date.toISOString().slice(0, 10);
    const [guest, setGuest] = useState(1);
    const [tom , settom] = useState();
    const [checkI, setCheckI] = useState(new Date() );
    
    const [checkOu, setCheckOu] = useState(new Date(Date.now() + ( 3600 * 1000 * 24) ));

    const [checkOut, setCheckOut] = useState();

    const [place, setPlace] = useState("Orangeloft Glamp park");
   
    const [checkIn , setcheckIn] = useState();
    console.log(checkOu)
    console.log(checkI)
    useEffect(() => {
        settom(new Date(Date.now() + ( 3600 * 1000 * 24) ) ) ;
        setcheckIn(checkI.toISOString().slice(0, 10) )
        setCheckOut(checkOu.toISOString().slice(0, 10))

        console.log(checkIn)
        console.log(checkOut);

        
      } , [checkI , checkOu ]   );
   
  
       const [count , setcount] = useState(0);
   
     
   
   
      const onHandleChange = (e) =>{
        setPlace(e.target.value);
       
        // setcount(1);
  
      }
 


   
    
   

    
    

    

    return (
        <div>
        <div className="container home-container mt-5">
        <div className="row">
        <div className="col-sm-12 text-center">
        <h1>Your Vaccation Starts Here</h1> 
        <p class="mob-p">Not sure where to spend lovely days? We're here to help</p>
        <a href="#stays" style={{ scrollBehavior: "smooth" }}>
        <button className="explore-btn mt-3">Explore</button></a>
        <div className="row mt-5" >
        <div className="col-sm-1">
        </div>


        <div className="col-sm-10 text-center">
        <div className="search-engine mt-3 w-100">
        <div className="row">
        <div className="col-sm-12">
        <form>
        <div className="row">

        <div className="col-sm-5">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Location</p>
        <select className="form-select location-dropdown "  onChange={onHandleChange}>
        <option>Where are you going?</option>
        {/* <option>Orangeloft Glamp park</option> */}
        <option > Mushkpuri</option>
        <option> Wildside Camping</option>
        <option > Kalabagh</option>
        <option > Naraan</option>
        <option> Shogran</option>
        <option > Attabad Lake</option>
        <option > Skardu (Lower Kuchura Lake)</option>
        <option> Walled City Lahore</option>
        <option> Frontier Malam Jabba</option>
        <option > Mahodand Lake</option>

        </select>
        
        </div>
        </div>
        </div>

        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Check In</p>
       
        {/* <input type="date"
         placeholder="Add dates"
         className="date"
         id="check-in"
         name="checkIn"
         value={checkIn}
         required
         onChange={(e)=>setCheckIn(e.target.value)} /> */}
          <DatePicker selected={checkI} onChange={(date) => setCheckI(date)}
            minDate={new Date()}
          />
  
        </div>
        </div>
        </div>
        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Check Out</p>
       
        {/* <input type="date" 
        placeholder="Add dates"
         className="date"
         id="check-out"
         name="checkOut"
         value={checkOut}
         required
         onChange={(e)=>setCheckOut(e.target.value)}  /> */}
          <DatePicker selected={checkOu} onChange={(date) => setCheckOu(date)}
            minDate={tom}
          />
  
        </div>
        </div>
        </div>
        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-6">
        <p className="search-label">Guests</p>
        <input type="number"
         placeholder="Add Guests" 
         className="guest"
         value={guest}
         min="1"
         max="2"
         required
         onChange={(e)=>setGuest(e.target.value)} />
        </div>
        </div>
        </div>

        <div className="col-sm-1  search-col">
            {  count == 1 ? 
        <Link to={`listing/${place}/${checkIn}/${checkOut}/${guest}`} className="nav-link1">
        <button 
        className="no-border"
    
       
        ><SearchIcon fontSize="large"  />
        </button>
        </Link> 
        :   <button 
        className="no-border"
        disabled={true}
       
        ><SearchIcon fontSize="large"  />
        </button>
         }


        <Link 
         to={`listing/${place}/${checkIn}/${checkOut}/${guest}`} 
         className="mob-search">
        <button 
       
        className="Search-btn"
        >
        Search
        </button>
        </Link>
        
        </div>
        </div>
        </form>
        
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>


      
        </div>
        </div>
        </div>
    
    );

}

