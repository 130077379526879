import React from "react";
import './App.css';
import { Link } from "react-router-dom";

const Host =() => {
    return (
        <div>
        <div className="container-fluid ">
        <div className="row no-gutters" >
        <div className="col-sm-6 host mb-5">
        <img src="/images/meeting2.jpg" alt="image" />
        </div>
        <div className="col-sm-6 mb-5 text-center  hosting-section center">
        <h1 className="section-heading text-white">Become a partner</h1>
        <p className="text-gray">Sign up as a host to open your <br /> doors to smiling faces and <br /> additional income</p>
        <Link to=""><button className="explore-btn mt-3">Explore</button></Link>
        </div>
        </div>
        </div>
        </div>
        );
}

export default Host;